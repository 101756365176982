import { defineAsyncComponent, markRaw } from 'vue';

const forms = [
    {
        name: 'GastroenterologiaForm',
        load: () => import('@/modules/Care/Consulta/forms/GastroenterologiaForm.vue'),
    },
    // {
    //     name: 'OftalmologiaForm',
    //     load: () => import('./OftalmologiaForm.vue'),
    // },
];

const useLoadForm = () => {
    const getComponent = (name) => {
        const component = forms.find((obj) => obj.name === name);

        if (!component) {
            throw Error('Component was not found');
        }

        const asyncComponent = defineAsyncComponent(() => {
            try {
                return component.load();
            } catch (error) {
                return false;
            }
        });
        if (!asyncComponent) {
            throw Error('Component was not load');
        }

        const rawComponent = markRaw(asyncComponent);

        return rawComponent;
    };

    return { getComponent };
};

export default useLoadForm;
