<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="darmin card">
                <ConsultaSetupInfo
                    v-model="state.setup"
                    :isReadOnly="state.id ? true : false"
                    @onChange="handlerFormSelected"
                ></ConsultaSetupInfo>
            </div>
            <div class="darmin card">
                <PacienteInfo
                    v-model="state.paciente"
                    @setInstitucion="setInstitucion"
                    :isReadOnly="state.id ? true : false"
                ></PacienteInfo>
            </div>
            <div class="darmin card">
                <DoctoresInfo
                    v-model="state.doctor"
                    :isReadOnly="disabled"
                    @onChange="handleSelectDoctor"
                    :maxDoctores="2"
                ></DoctoresInfo>
            </div>

            <component :is="form" :v="v" v-model="state" :disabled="disabled" />

            <!--Renders-->
            <ConsultaRenderedFiles
                v-if="state.documentStatus >= 2"
                :files="state.renders"
                :disabled="state.documentStatus === 3"
                @onRemoveRender="HanlderRemoveRender"
                @onChange="handleAction('save')"
            />
            <!--/Renders-->

            <!--Render Buttons Bar-->
            <div
                class="darmin card header-card fixed-row"
                v-if="state.id && state.documentStatus === 2 && consultaSetup"
            >
                <div class="row">
                    <RenderButtonsBar :setup="consultaSetup" @onClick="handleClickRenderBar" />
                </div>
            </div>
            <!--/Render Buttons Bar-->

            <Observaciones
                :observaciones="state.observaciones"
                @onAddObservacion="handleAddNewObservacion"
                @onDeleteObservacion="handledeleteNewObservacion"
            />

            <!--Barra de Accion-->
            <div class="darmin card header-card border">
                <div class="row">
                    <div class="col-12 text-end">
                        <div
                            class="btn btn-warning ms-3 mb-1"
                            v-if="state.id && state.documentStatus === 2 && !state.renders.length"
                            @click="handleAction('goback')"
                        >
                            <i class="far fa-edit"></i>
                            Editar
                        </div>
                        <div
                            class="btn btn-success ms-3 mb-1"
                            v-if="state.id && state.documentStatus === 3"
                            @click="handleAction('resend')"
                        >
                            <i class="far fa-file-import"></i>
                            Reenviar
                        </div>
                        <div
                            class="btn btn-success ms-3 mb-1"
                            v-if="state.id && state.documentStatus === 2 && !state.renders.length"
                            @click="handleAction('build_docs')"
                        >
                            <i class="far fa-file-import"></i>
                            Generar Documentos
                        </div>
                        <a
                            class="btn btn-success ms-3 mb-1"
                            target="_blank"
                            v-if="state.id && state.documentStatus === 2 && state.renders.length"
                            @click="handleAction('publish')"
                        >
                            <i class="far fa-file-alt"></i>
                            Publicar
                        </a>
                        <a
                            class="btn btn-warning ms-3 mb-1"
                            target="_blank"
                            v-if="state.id && state.documentStatus === 3"
                            @click="handleViewDocuments"
                        >
                            <i class="far fa-file-import"></i>
                            Ver Documentos
                        </a>
                        <div
                            class="btn btn-success ms-3 mb-1"
                            v-if="state.id && state.documentStatus === 1"
                            @click="handleAction('close')"
                        >
                            <i class="far fa-file-import"></i>
                            Finalizar
                        </div>
                        <div
                            class="btn btn-primary ms-3 mb-1"
                            v-if="state.id && state.documentStatus === 1"
                            @click="handleAction('save')"
                        >
                            <i class="far fa-save"></i>
                            Guardar
                        </div>
                        <div
                            class="btn btn-primary ms-3 mb-1"
                            v-if="!state.id"
                            @click="handleAction('create')"
                        >
                            <i class="far fa-save"></i>
                            Crear
                        </div>
                        <div
                            class="btn btn-primary ms-3 mb-1"
                            v-if="!state.id"
                            @click="handleAction('create_view')"
                        >
                            <i class="far fa-save"></i>
                            Crear y mostrar
                        </div>
                    </div>
                </div>
            </div>
            <!--/Barra de Accion-->
        </div>

        <pre v-if="env === 'development'">{{ JSON.stringify(state, null, 4) }}</pre>
    </WindowLayout>
</template>

<script setup>
import { defineProps, onMounted, ref } from 'vue';

import WindowLayout from '@/layouts/Window.layout.vue';

import useConsultaForm from './services/useConsultaForm';
import ConsultaSetupInfo from '../ConsultaSetup/components/ConsultaSetupInfo.vue';
import PacienteInfo from '../Pacientes/components/PacienteInfo.vue';
import DoctoresInfo from '../Doctores/components/DoctoresInfo.vue';
import Observaciones from '../Components/Observaciones.vue';

import useLoadForm from './forms/useLoadForm';
import ConsultaRenderedFiles from './components/ConsultaRenderedFiles.vue';
import RenderButtonsBar from './components/RenderButtonsBar.vue';

import useConsulta from './services/useConsulta';
import useConsultaSetup from '../ConsultaSetup/services/useConsultaSetup';

const props = defineProps({ title: String, item: Object });

const env = process.env.NODE_ENV;
const { getComponent } = useLoadForm();

const isReady = ref(false);

const consultaService = useConsulta();
// eslint-disable-next-line object-curly-newline
const {
    state,
    setState,
    v,
    handleAction,
    disabled,
    setInstitucion,
    handleAddNewObservacion,
    handledeleteNewObservacion,
} = useConsultaForm(consultaService);

const { consultaSetup, getConsultaSetup } = useConsultaSetup();

const form = ref();

const handlerFormSelected = (setup) => {
    console.log('setup', setup);
    form.value = getComponent(setup.formType);
};

const handleSelectDoctor = (specialties) => {
    state.specialty = specialties;
};

onMounted(async () => {
    if (props.item) {
        if (props.item.id) {
            await Promise.all([consultaService.getConsulta(props.item.id)]);
            if (consultaService.consulta.value) {
                if (props.action && props.action === 'observacion') {
                    // eslint-disable-next-line object-curly-newline, operator-linebreak
                    const { paciente, doctor, specialty, data } = consultaService.consulta.value;
                    consultaService.consulta.value = {
                        paciente,
                        doctor,
                        specialty,
                        data,
                    };
                }
                console.log('setState', consultaService.consulta.value.data);
                setState(consultaService.consulta.value);
                await getConsultaSetup(consultaService.consulta.value.setup);
            }
        } else {
            const tempState = { ...state };
            if (props.item.paciente) {
                tempState.paciente = props.item.paciente;
            }
            if (props.item.doctor) {
                tempState.doctor = props.item.doctor;
            }
            if (props.item.setup) {
                tempState.setup = props.item.setup;
            }
            setState(tempState);
        }
        if (props.item.citaId) {
            state.citaId = props.item.citaId;
        }
    }
    isReady.value = true;
});
</script>
